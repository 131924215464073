<!-- Loading bar -->
<loading-bar></loading-bar>

<!-- Navigation -->
<vertical-navigation
  class="dark bg-gray-900 print:hidden"
  [appearance]="navigationAppearance"
  [mode]="isScreenSmall ? 'over' : 'side'"
  [name]="'mainNavigation'"
  [navigation]="navigation?.default"
  [opened]="!isScreenSmall"
  [offMarginTop]="true"
>
  <!-- Navigation header hook -->
  <ng-container verticalNavigationHeader>

    @if (navigationAppearance !== 'dense'){
    <div class="flex items-center justify-center h-16 border-b cursor-pointer" routerLink="/home">
      <img
        class="w-full max-w-26 mx-9 max-h-[30px]"
        src="assets/images/logo/logo-text-on-dark.svg"
        alt="Logo image">
    </div>
    } @else {
      <div class="flex items-center justify-center h-16 cursor-pointer" routerLink="/home">
        <img
          class="w-auto max-w-26 mx-2 max-h-[30px]"
          src="assets/images/logo/logo-text-on-dark.svg"
          alt="Logo image">
      </div>
    }

  </ng-container>
  <ng-container verticalNavigationContentHeader>
<!--    <div class="flex items-center justify-center h-20">-->

<!--    </div>-->
  </ng-container>
</vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">
    <!-- Header -->
    <div class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-10 border-b bg-card dark:bg-transparent print:hidden">
        <div class="flex items-center pr-2 space-x-2">
            <!-- Navigation toggle button -->
            <button
              class="inline-flex md:hidden"
                mat-icon-button
                (click)="toggleNavigation('mainNavigation')">
                <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
            </button>
            <!-- Navigation appearance toggle button -->
            <button  [matTooltip]="navigationAppearance == 'dense' ? 'Rowziń menu' : 'Zwiń menu'"
                class="hidden md:inline-flex"
                mat-icon-button
                (click)="toggleNavigationAppearance()" >
                <mat-icon [svgIcon]="'heroicons_outline:arrows-right-left'"></mat-icon>
            </button>
        </div>
        <!-- Components -->
        <div  class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
<!--            <search [appearance]="'bar'"></search>-->
<!--            <shortcuts></shortcuts>-->
<!--            <messages></messages>-->
<!--            <notifications></notifications>-->
<!--            <button-->
<!--                class="lg:hidden"-->
<!--                mat-icon-button-->
<!--                (click)="quickChat.toggle()">-->
<!--                <mat-icon [svgIcon]="'heroicons_outline:chat-bubble-left-right'"></mat-icon>-->
<!--            </button>-->
          <asf-fullscreen class="hidden md:block"></asf-fullscreen>
          <app-submissions-add></app-submissions-add>
          <app-settings-navigation></app-settings-navigation>
          <user class="flex"></user>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
<!--    <div class="relative flex flex-0 items-center justify-start w-full px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden pt-4 md:pb-4 pb-24 ">-->
<!--        <span class="font-medium text-secondary">ASF360 &copy; {{currentYear}}</span>-->
<!--    </div>-->
<!--  <mobile-navigation-->
<!--    *ngIf="isScreenSmall"-->
<!--    [name]="'mobileNavigation'"-->
<!--    [navigation]="navigation.mobile"-->
<!--    (activeToggle)="toggleNavigation($event)"-->
<!--  ></mobile-navigation>-->

</div>

<!-- Quick chat -->
<!--<quick-chat #quickChat="quickChat"></quick-chat>-->
