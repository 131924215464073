import { Injectable } from '@angular/core';
import {BehaviorSubject, first, Observable, ReplaySubject, take, tap} from "rxjs";
import {Dictionary} from "../interface/dictionary";
import {HttpClient} from "@angular/common/http";
import {ListResponse} from "../interface/response/list-response";
import {ContractBalance} from "../interface/contract-balance";
import {AuthService} from "./auth.service";

export const OFWCA_STATUS = 'ofwca_status';
export const OFWCA_STATUS_ACTIVE = 'active';
export const OFWCA_STATUS_NEW = 'new';
export const OFWCA_STATUS_INACTIVE = 'inactive';

export const POST_TAG_TYPE = 'post_tag_type';
export const POST_TAG_TYPE_INSURANCE_COMPANY = 'insurance_company';
export const POST_TAG_TYPE_POST_TYPE = 'post_type';
export const POST_TAG_TYPE_DEPARTMENT_TYPE = 'department_type';

export const CONTRACT_STATUS = 'contract_status';
export const CONTRACT_STATUS_NEW = 'new';
export const CONTRACT_STATUS_TO_TERMINATE = 'to_terminate';
export const CONTRACT_STATUS_DURING_TERMINATION = 'during_termination';
export const CONTRACT_STATUS_TERMINATED = 'terminated';
export const CONTRACT_STATUS_ACTIVE = 'active';
export const CONTRACT_STATUS_CANCELED = 'canceled';
export const CONTRACT_STATUS_CLOSED = 'closed';
export const CONTRACT_STATUS_SENT_WITH_AUTENTI = 'sent_with_autenti';
export const CONTRACT_STATUS_SENT_TO_AGENT = 'sent_to_agent';
export const CONTRACT_STATUS_DOWNLOADED = 'downloaded';
export const CONTRACT_STATUS_HANDED_OVER_REGIONAL = 'handed_over_regional';


export const CONTRACT_TYPE = 'contract_type';
export const CONTRACT_TYPE_PARTNERSHIP = 'partnership';
export const CONTRACT_TYPE_PROFIT = 'profit';
export const CONTRACT_TYPE_MANDATE = 'mandate';
export const CONTRACT_TYPE_TERMINAL = 'terminal';
export const CONTRACT_TYPE_FRANCHISE = 'franchise';
export const CONTRACT_TYPE_OCK = 'ock';
export const CONTRACT_TYPE_LEASING = 'leasing';
export const CONTRACT_TYPE_DURING_REGISTRATION = 'during_registration';
export const CONTRACT_TYPE_LEAD = 'lead';
export const CONTRACT_TYPE_FOR_DEREGISTRATION = 'for_deregistration';
export const CONTRACT_TYPE_SELF_BILLING = 'self_billing';
export const CONTRACT_TYPE_AGREEMENT_FOR_PARTNER_POINTS = 'agreement_for_partner_points';
export const CONTRACT_TYPE_FRANCHISE_AGREEMENT = 'franchise_agreement';


export const REGISTRATION_STATUS = 'registration_status';
export const REGISTRATION_STATUS_REGISTRATION_REQUEST = 'registration_request';
export const REGISTRATION_STATUS_SIGNED_UP_FOR_AN_EXAM = 'signed_up_for_an_exam';
export const REGISTRATION_STATUS_SENT_TO_REGISTER_IN_INSURANCE_COMPANY = 'sent_to_register_in_insurance_company';
export const REGISTRATION_STATUS_EXAM_PASSED = 'exam_passed';
export const REGISTRATION_STATUS_INCORRECT_DOCUMENTS = 'incorrect_documents';
export const REGISTRATION_STATUS_TO_BLOCK = 'to_block';
export const REGISTRATION_STATUS_TO_DEREGISTER = 'to_deregister';
export const REGISTRATION_STATUS_SENT_TO_DEREGISTER = 'sent_to_deregister';
export const REGISTRATION_STATUS_DEREGISTERED = 'deregistered';
export const REGISTRATION_STATUS_REGISTERED = 'registered';
export const REGISTRATION_STATUS_SENT_TO_BLOCK = 'sent_to_block';
export const REGISTRATION_STATUS_BLOCKED = 'blocked';
export const REGISTRATION_STATUS_RESIGNATION = 'resignation';
export const REGISTRATION_STATUS_DENIED_BY_INSURANCE_COMPANY = 'denied_by_insurance_company';
export const REGISTRATION_STATUS_SUSPENDED = 'suspended';


export const COMPANY = 'company';
export const COMPANY_ASF = 'asf';
export const COMPANY_ASF_PREMIUM = 'asf_premium';

export const CONTACT_TYPE = 'contract_type';
export const CONTACT_TYPE_PRIMARY = 'primary';
export const CONTACT_TYPE_BILLING = 'billing';
export const CONTACT_TYPE_MARKETING = 'marketing';

export const SOLD_POLICY_TYPE = 'sold_policy_type';
export const SOLD_POLICY_TYPE_NEW = 'new';
export const SOLD_POLICY_TYPE_RENEWAL = 'renewal';
export const SOLD_POLICY_TYPE_AUTO_RENEWAL = 'auto_renewal';
export const SOLD_POLICY_TYPE_MANUAL_RENEWAL = 'manual_renewal';
export const SOLD_POLICY_TYPE_FOREIGN_INSTALLMENT = 'foreign_installment';

export const RECEIVABLES_IMPORT_ROW_STATUS = 'receivables_status';
export const RECEIVABLES_IMPORT_ROW_STATUS_NEW = 'new';
export const RECEIVABLES_IMPORT_ROW_STATUS_FOR_VERIFICATION = 'for_verification';
export const RECEIVABLES_IMPORT_ROW_STATUS_CANCELLED = 'cancelled';
export const RECEIVABLES_IMPORT_ROW_STATUS_PAID = 'paid';
export const RECEIVABLES_IMPORT_ROW_STATUS_PARTIALLY_PAID = 'partially_paid';
export const RECEIVABLES_IMPORT_ROW_STATUS_PARTIALLY_REPORTED_FOR_SETTLEMENT = 'reported_for_settlement';
export const RECEIVABLES_IMPORT_ROW_STATUS_UNPAID = 'unpaid';



@Injectable({
  providedIn: 'root'
})
export class DictService {
  loaded = false;

  private readonly dictSubject: ReplaySubject<Array<Dictionary>> = new ReplaySubject();
  private _dictionary: BehaviorSubject<Dictionary | null> = new BehaviorSubject(null);
  private _postTag: BehaviorSubject<Dictionary | null> = new BehaviorSubject(null);
  private _company: BehaviorSubject<Dictionary | null> = new BehaviorSubject(null);
  private _dictionaries: BehaviorSubject<Dictionary[] | null> =
    new BehaviorSubject(null);

  constructor(
    private _httpClient: HttpClient,
    private _authService: AuthService,
  ) {
    // this.loadDict();
  }

  clearCompany(){
    this._company.next(null);
  }
  /**
   * Getter for dictionary
   */
  get company$(): Observable<Dictionary> {
    return this._company.asObservable()
  }

  /**
   * Getter for dictionary
   */
  get dictionary$(): Observable<Dictionary> {
    return this._dictionary.asObservable()
  }

  /**
   * Getter for dictionaries
   */
  get dictionaries$(): Observable<Dictionary[]> {
    return this._dictionaries.asObservable()
  }

  loadDict(): Observable<ListResponse<Dictionary>> {
    return this._httpClient.get<ListResponse<Dictionary>>('/rest/crm/dictionary')
      .pipe(
        tap((response) => {
          this.dictSubject.next(response.items);
          this.loaded = true;
          this._dictionaries.next(response.items)
        })
      );
  }

  getDictSubject(): ReplaySubject<Array<Dictionary>> {
    return this.dictSubject;
  }

  getKey(id: number): Observable<string> {
    this.loadIfNotLoaded();
    const keyObservable: ReplaySubject<string> = new ReplaySubject<string>(1);

    this.dictSubject.pipe(first())
      .subscribe((items: Dictionary[]) => {
        items.some((el: Dictionary) => {
          if (el.id === id) {
            keyObservable.next(el.key);
            keyObservable.complete();
            return true;
          }

          return false;
        });

        keyObservable.next('');
        keyObservable.complete();
      });

    return keyObservable.pipe(first());
  }

  getId(key: string, type: string): Observable<number> {
    this.loadIfNotLoaded();
    const idObservable: ReplaySubject<number> = new ReplaySubject<number>(1);

    this.dictSubject.pipe(first())
      .subscribe((items: Dictionary[]) => {
        items.some((el: Dictionary) => {
          if (el.key === key && el.type === type) {
            idObservable.next(el.id);
            idObservable.complete();
            return true;
          }

          return false;
        });

        idObservable.next(-1);
        idObservable.complete();
      });

    return idObservable.pipe(first());
  }

  get(key: string, type: string): Observable<Dictionary> {
    this.loadIfNotLoaded();
    const idObservable: ReplaySubject<Dictionary> = new ReplaySubject<Dictionary>(1);

    this.dictSubject.pipe(first())
      .subscribe((items: Dictionary[]) => {
        items.some((el: Dictionary) => {
          if (el.key === key && el.type === type) {
            idObservable.next(el);
            idObservable.complete();
            this._company.next(el);
            return true;
          }

          return false;
        });

        idObservable.next(null);
        idObservable.complete();
      });

    return idObservable.pipe(first());
  }

  getDict(id: number): Observable<Dictionary> {
    this.loadIfNotLoaded();
    const dictObservable: ReplaySubject<Dictionary> = new ReplaySubject<Dictionary>(1);

    this.dictSubject.pipe(first())
      .subscribe((items: Dictionary[]) => {
        items.some((el: Dictionary) => {
          if (el.id === id) {
            dictObservable.next(el);
            dictObservable.complete();
            return true;
          }

          return false;
        });

        dictObservable.complete();
      });

    return dictObservable.pipe(first());
  }

  private loadIfNotLoaded(): void {
    if (!this.loaded) {
      this.loadDict();
    }
  }
}
